<template>
  <div class="create-ticketcnt">
    <header class="inner-header">
      <span class="icon-Btn goback" @click="$router.go(-1)">
        <back-icon />
      </span>
      <h1>Create Ticket</h1>
    </header>
    <section class="mid-cnt">
      <div class="form-box">
        <h2>For any queries, please contact our customer support.</h2>
        <div class="field-row">
          <label class="text-label">What do you need help with?</label>
          <div class="pos-relate">
            <select class="select-opt">
              <option value="Select Category">Select Category</option>
              <option value="Category 1">Category 1</option>
              <option value="Category 2">Category 2</option>
            </select>
            <span class="drop-icon">
              <arrow-bottom-icon />
            </span>
          </div>
        </div>

        <div class="field-row">
          <label class="text-label">Add Details</label>
          <textarea
            class="textare-style"
            placeholder="Type Here ..."
          ></textarea>
        </div>

        <div class="field-row">
          <button class="button">Create Ticket</button>
        </div>
        <div class="or-text">
          <span>या फिर</span>
        </div>

        <div class="phone-txtcard">
          <span class="icon-Btn phone-icon">
            <call-icon />
          </span>
          <div class="data-col">
            <a href="tel:+01143092005" class="phone-link">01143092005</a>
            <p>Phone support is available Monday to Saturday, 10AM till 7PM</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BackIcon from '@/components/SvgIcons/BackIcon';
import ArrowBottomIcon from '@/components/SvgIcons/ArrowBottomIcon';
import CallIcon from '@/components/SvgIcons/CallIcon';

export default {
  name: 'CreateTicket',
  components: {
    BackIcon,
    ArrowBottomIcon,
    CallIcon,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/create-ticket.scss';
</style>
